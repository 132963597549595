import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCompanyList() {
  // Use toast
  const toast = useToast()

  const refCompanyListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'company', label: 'Ragione Sociale', sortable: true },
    { key: 'name', label: 'Nome', sortable: true },
    { key: 'contact', label: 'Contatto', sortable: true },
    { key: 'address', label: 'Indirizzo', sortable: true },
    { key: 'actions', label: 'Azioni' },
  ]
  const perPage = ref(10)
  const totalCompanies = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refCompanyListTable.value ? refCompanyListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCompanies.value,
    }
  })

  const refetchData = () => {
    refCompanyListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchCompanies = (ctx, callback) => {
    store
      .dispatch('app-company/fetchCompanies', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const { companies, total } = response.data

        callback(companies)
        totalCompanies.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching companies' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }


  return {
    fetchCompanies,
    tableColumns,
    perPage,
    currentPage,
    totalCompanies,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCompanyListTable,

    refetchData,
  }
}
