<template>

  <!-- Table Container Card -->
  <b-card
    title="Compagnie"
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <!-- <label>Entries</label> -->
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            @click="addEditCompanyModal(-1)"
          >
            Nuovo
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Cerca..."
            />
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refCompanyListTable"
      :items="fetchCompanies"
      responsive
      :fields="tableColumns"
      primary-key="Id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #head(company)>
        <feather-icon
          icon="GlobeIcon"
          class="mx-auto"
        /> Ragione Sociale
      </template>
      <template #head(address)>
        <feather-icon
          icon="MapPinIcon"
          class="mx-auto"
        /> Indirizzo
      </template>
      <template #head(contact)>
        <feather-icon
          icon="PhoneIcon"
          class="mx-auto"
        /> Contatto
      </template>
      <template #head(name)>
        <feather-icon
          icon="UserIcon"
          class="mx-auto"
        /> Referente
      </template>
      <template #head(actions)>
        <feather-icon
          icon="ZapIcon"
          class="mx-auto"
        /> Azioni
      </template>

      <!-- Column: Id -->
      <template #cell(Id)="data">
        <b-link
          class="font-weight-bold"
          @click="addEditCompanyModal(data.item.Id)"
        >
          #{{ data.value }}
        </b-link>
      </template>

      <!-- Column: Client -->
      <template #cell(company)="data">
        <b-link
          class="font-weight-bold"
          @click="addEditCompanyModal(data.item.Id)"
        >
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.company)"
                variant="light-primary"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.company }}
            </span>
            <small class="text-muted">{{ data.item.companyEmail }}</small>
          </b-media>
        </b-link>
        <company-add-modal
          :indexref="`${data.item.Id}`"
          :company="data.item"
          @refetchData="refetchData"
        />
      </template>
      <!-- Column: Client -->
      <template #cell(address)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.address }}
          </span>
          <small class="text-muted">{{ data.item.country }}</small>
        </b-media>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">

          <feather-icon
            :id="`company-row-${data.item.Id}-edit-icon`"
            icon="EditIcon"
            size="16"
            class="mx-1"
            @click="addEditCompanyModal(data.item.Id)"
          />
          <b-tooltip
            title="Modifica"
            :target="`company-row-${data.item.Id}-edit-icon`"
          />
          <!-- TODO Delete Company
             <feather-icon
            :id="`company-row-${data.item.Id}-delete-icon`"
            icon="TrashIcon"
            size="16"
            class="mx-1"
          />
          <b-tooltip
            title="Elimina"
            :target="`company-row-${data.item.Id}-delete-icon`"
          /> -->
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Vedi da {{ dataMeta.from }} a {{ dataMeta.to }} su {{ dataMeta.of }} elementi</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalCompanies"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
    <company-add-modal
      indexref="-1"
      @refetchData="refetchData"
    />
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import CompanyAddModal from './CompanyAddModal.vue'
import useCompanyList from './useCompanyList'

import companyStoreModule from './companyStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BTooltip,

    vSelect,
    CompanyAddModal,
  },
  methods: {
    addEditCompanyModal(id) {
      this.$bvModal.show(`add-company-${id}`)
    },
  },
  setup() {
    const COMPANY_DEALER_STORE_MODULE_NAME = 'app-company'

    // Register module
    if (!store.hasModule(COMPANY_DEALER_STORE_MODULE_NAME)) store.registerModule(COMPANY_DEALER_STORE_MODULE_NAME, companyStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COMPANY_DEALER_STORE_MODULE_NAME)) store.unregisterModule(COMPANY_DEALER_STORE_MODULE_NAME)
    })

    const {
      fetchCompanies,
      tableColumns,
      perPage,
      currentPage,
      totalCompanies,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCompanyListTable,

      refetchData,

    } = useCompanyList()

    return {
      fetchCompanies,
      tableColumns,
      perPage,
      currentPage,
      totalCompanies,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCompanyListTable,

      refetchData,

      avatarText,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .company-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>

