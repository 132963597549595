<template>
  <section class="company-add-wrapper">
    <b-modal
      :id="'add-company-'+indexref"
      :ref="'add-company-'+indexref"
      centered
      hide-footer
      size="lg"
      title=" Company"
    >
      <b-row class="match-height">
        <b-col
          md="12"
          lg="12"
        >
          <b-card>
            <validation-observer
              #default="{ handleSubmit }"
              ref="CompanyRules"
            >
              <b-form @submit.prevent="handleSubmit(saveCompany())">
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label>Ragione Sociale</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="CardName"
                      >
                        <b-form-input
                          v-model="Company.company"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Ragione Sociale"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>Referente</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="name"
                      >
                        <b-form-input
                          v-model="Company.name"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Referente"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>E-Mail</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Mail"
                      >
                        <b-form-input
                          v-model="Company.companyEmail"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Mail"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>Contatto</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="contact"
                      >
                        <b-form-input
                          v-model="Company.contact"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Contatto"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      label="Indirizzo"
                      label-for="fh-desc"
                    >
                      <b-form-textarea
                        id="fh-desc"
                        v-model="Company.address"
                        placeholder="Descrizione"
                        rows="3"
                        max-rows="8"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Stato"
                      label-for="country"
                    >
                      <v-select
                        v-model="Company.country"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :clearable="true"
                        :options="countries"
                        label="name"
                        index="code"
                        class="fixborder"
                        :reduce="val => val.code"
                        input-id="country"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- submit and reset -->
                  <b-col md="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      {{ (Company.Id)? "Salva" : "Aggiungi" }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                      @click="reset"
                    >
                      Reset
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>
<script>
import store from '@/store'
import {
  ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BButton,
  BCard,
  BFormTextarea,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BMedia, BMediaAside, BAvatar,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import { getUserData } from '@/auth/utils'
import { required, alphaNum, email } from '@validations'
import vSelect from 'vue-select'
import { formatDate } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import companyStoreModule from './companyStoreModule'
// eslint-disable-next-line import/no-cycle

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BCard,
    BFormTextarea,
    BFormInput,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    indexref: {
      type: String,
      default: "0",
    },
    // eslint-disable-next-line vue/prop-name-casing
    Company: {
      type: Object,
      required: false,
      // eslint-disable-next-line vue/require-valid-default-prop
      default() {
        return {
          Id: null,
          address: "",
          company: "",
          companyEmail: "",
          contact: "",
          name: "",
          country: "IT",
        }
      },
    },
  },
  setup(props) {
    const userData = getUserData()
    const countries = ref([])

    const COMPANY_DEALER_STORE_MODULE_NAME = 'app-company'
    // Register module
    /*
    if (!store.hasModule(COMPANY_DEALER_STORE_MODULE_NAME)) store.registerModule(COMPANY_DEALER_STORE_MODULE_NAME, companyStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COMPANY_DEALER_STORE_MODULE_NAME)) store.unregisterModule(COMPANY_DEALER_STORE_MODULE_NAME)
    })
*/
    // if (props.Company.id) {
    //   store.dispatch(`${COMPANY_DEALER_STORE_MODULE_NAME}/fetchCompany`, { id: props.Company.id }).then(response => {
    //     console.log(response)
    //     props.Company = response.data
    //   }).catch(error => {

    //   })
    // } else {
    //   props.Company = {
    //     id: null,
    //     address: "",
    //     company: "",
    //     companyEmail: "",
    //     contact: "",
    //     name: "",
    //     country: "IT",
    //   }
    // }

    store.dispatch(`${COMPANY_DEALER_STORE_MODULE_NAME}/fetchCountries`, { })
      .then(res => {
        countries.value = res.data // .data.ohem.map(x => ({ label: `${x.lastName} ${x.firstName}`, value: x.empID }))
        // countries.value.push({ label: '', value: -1 })
      })
      .catch(error => {
        if (error.response.status === 404) {
          countries.value = undefined
        }
      })


    return {
      userData,
      countries,
      required,
      alphaNum,
      email,
    }
  },
  methods: {
    saveCompany() {
      this.$refs.CompanyRules.validate().then(success => {
        if (success) {
          store.dispatch('app-company/AddUpdateCompany', { company: this.Company, userData: this.userData })
            .then(response => {
              this.$bvModal.hide(`add-company-${this.indexref}`)
              this.$emit('refetchData')
            }).catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    }
    ,
  },
}
</script>

